import * as $ from 'jquery';
import 'foundation-sites';

import LoginPassword from "./components/password";
import Login from "./components/login";

import '../scss/main.scss';

$(()=>{

    // components
    let login = new Login('login-form');
    let form = new LoginPassword('patient_password');

    login.init();
    form.init();

    $(document).foundation();

    const $acceptCheckbox = $("#patient_agreement_agreedWithTerms_0");
    const $declineCheckbox = $("#patient_agreement_agreedWithTerms_1");
    const $agreeCheckbox = $(".privacyBox .regular-checkbox");
    const $submitButton = $("#questionnaireStartButton");

    $acceptCheckbox.prop("checked", true);

    $declineCheckbox.on('change', function(e) {
        if ($declineCheckbox.prop('checked') === true) {
            $agreeCheckbox.prop('checked', false);
            $submitButton.addClass('alert');
            $submitButton.text($submitButton.data('decline'));
        }
    });

    $agreeCheckbox.on('change', function(e) {
        if ($(this).prop('checked') === true) {
            $declineCheckbox.prop('checked', false);
            $submitButton.removeClass('alert');
            $submitButton.text($submitButton.data('accept'));
        }
    });
});