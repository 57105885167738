import $ from 'jquery';
import 'jquery-validation';
import FormTranslate from "./formTranslate";

class Login extends FormTranslate {

    public fields:any = [];

    get formId(): string {
        return this._formId;
    }

    private _formId:string;


    constructor(formId:string, locale:string = 'nl') {

        super(locale);

        this._formId = formId;


    }
    public init() {

        $('form[name='+this.formId+']').validate({
            rules: {
                "_username": {
                    required: true,
                    email: true
                },
                "_password": {
                    required:true
                }
            }
        });


    }
}

export default Login;