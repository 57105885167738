import $ from 'jquery';
import 'jquery-validation';
import FormTranslate from "./formTranslate";

class LoginPassword extends FormTranslate {

    get formId(): string {
        return this._formId;
    }

    private _formId: string;

    constructor(formId: string, locale: string = "nl") {
        super(locale);
        this._formId = formId;
    }

    public init() {

        $.validator.addMethod("pwcheck", (value) => {
            return /^[A-Za-z0-9\d=!\-@._*]*$/.test(value) // consists of only these
                && /[a-z]/.test(value) // has a lowercase letter
                && /\d/.test(value) // has a digit
                && /[=!\-@._*]/.test(value)  // has a special character
        }, "Wachtwoord moet minstens één cijfer en één speciaal teken bevatten.");

        $('form[name=' + this.formId + ']').validate({
            rules: {
                "patient_password[plainPassword][first]": {
                    minlength: 8,
                    pwcheck: true
                },
                "patient_password[plainPassword][second]": {
                    minlength: 8,
                    equalTo: ".password"
                }
            }
        });
    }
}

export default LoginPassword;
